* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  width: 100%;
  scroll-behavior: smooth;
}
body {
  width: 100%;
  height: auto;
  overflow-x: auto;
  background: #fff;
}
@font-face {
  font-family: "Helvetica";
  src: url(../font/Helvetica.ttf);
}
:root {
  --primary: #2877ff;
  --secondary: #75d526;
  --gray: #efefef;
  --radius: 5px;
  --shadow: 0px 1px 2px 0px var(--gray);
  --transition: all 0.5s ease-in-out;
}
h1 {
  font-size: 1.5rem;
  margin: 0;
  font-family: "Helvetica";
  text-transform: capitalize;
  font-weight: bold;
}
h2 {
  font-size: 1.25rem;
  margin: 0;
  font-family: "Helvetica";
  text-transform: capitalize;
  font-weight: bold;
}
p,
a,
li,
label,
select,
input,
textarea,
button,
th,
td {
  font-size: 1rem;
  margin: 0;
  font-family: "Helvetica";
}
ul {
  margin: 0;
}
a {
  text-decoration: none;
}
label {
  text-transform: capitalize;
  display: block;
}
.input {
  width: 100%;
  padding: 5px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  outline: none;
  border: 1px solid #bdbdbd;
  transition: var(--transition);
}
.input:focus {
  border: 1px solid var(--primary);
  box-shadow: var(--shadow);
}
.button {
  text-align: center;
  border: none;
  outline: none;
  background: var(--primary);
  color: #fff;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: var(--radius);
  transition: var(--transition);
}
.red-button {
  text-align: center;
  border: none;
  outline: none;
  background: red;
  color: #fff;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: var(--radius);
  transition: var(--transition);
}
.white-button {
  text-align: center;
  border: none;
  outline: none;
  background: #fff;
  color: var(--primary);
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: var(--radius);
  transition: var(--transition);
}
.button:hover,
.red-button:hover,
.white-button:hover {
  box-shadow: var(--shadow);
}
nav {
  background: var(--primary);
}
.delete-icon {
  cursor: pointer;
  color: red;
}
.modal-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0000006b;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
}
.modal-background.active {
  top: 50%;
  opacity: 1;
  pointer-events: all;
}
.modal-container {
  width: 400px;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 8px;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  border: 1px solid var(--gray);
}
.enroll-form {
  width: 576px;
  margin: 0 auto;
  border: 1px solid #bdbdbd;
  border-radius: var(--radius);
  padding: 8px;
  box-shadow: var(--shadow);
}
.form-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.card-container {
  width: 100%;
  overflow-x: auto;
}
.my-card {
  display: grid;
  grid-template-columns: repeat(6, 300px);
  align-items: start;
  justify-content: start;
  transition: var(--transition);
}
.my-card > div {
  height: 100%;
  border-bottom: 1px solid var(--gray);
  padding: 8px;
}
.page-link {
  color: var(--primary);
}
.active > .page-link {
  background: var(--primary);
  border: 1px solid var(--primary);
}
.page-link:focus {
  box-shadow: none;
}
.page-link:hover {
  color: unset;
}
.user-icon {
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  transition: var(--transition);
}
.user-icon:hover {
  color: red;
}
nav ul {
  list-style-type: none;
}
nav ul li a {
  color: #fff;
  padding: 4px 8px;
  border-radius: var(--radius);
}
nav ul li a.active {
  background: var(--secondary);
}
.creditor-container {
  width: 100%;
  overflow-x: auto;
}
.creditor-container .creditor-table {
  min-width: 768px;
  width: 100%;
}
.creditor-container .creditor-table th {
  background: var(--primary);
  color: #fff;
  font-weight: 100;
}
th,
td {
  padding: 16px 8px !important;
}
.logo {
  width: 200px;
  height: auto;
  background: #fff;
  padding: 0 4px 4px;
  border-radius: var(--radius);
}
img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
