@media screen and (max-width: 576px) {
  .modal-container {
    width: calc(100% - 16px);
  }
}

@media (max-width: 768px) {
  .table-responsive {
    overflow-x: auto;
  }
  .table-responsive table {
    min-width: 768px;
  }
}
